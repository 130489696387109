<template>
<div v-if="loaded" class="opacity-page">
	<div v-if="notPaid">
		<page-not-paid />
	</div>
	<div v-else>
		DASHBOARD!
	</div>
</div>
</template>

<script>
    import PageNotPaid from '../General/NotPaid'
	export default {
		components: {
			PageNotPaid
		},
		data() {
			return {
				loaded: false,
				notPaid: false,
			}
		},
		async mounted() {
			if(!this.$auth.user().instance || this.$auth.user().instance.status == 'unpaid' || this.$auth.user().instance.status == 'new'){
				this.loaded = true
				this.notPaid = true

				setTimeout(() => {
					$('.opacity-page').addClass('show')
					var title = this.$t('navbar.dashboard');
					this.$root.$emit("navbar_title", title);
				}, 0)
			}else{
				setTimeout(() => {
					$('.opacity-page').addClass('show')
					var title = this.$t('navbar.dashboard');
					this.$root.$emit("navbar_title", title);
				}, 0)
			}
		},
}
</script>